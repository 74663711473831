import { TorrTorrentInfo } from "./types";

export const randomTorrent: TorrTorrentInfo = {
  hash: "",
  added_on: 1659705104,
  amount_left: 0,
  auto_tmm: true,
  availability: -1,
  category: "",
  completed: 16426827616,
  completion_on: 1659706069,
  content_path:
    "/Volumes/20TeraBoy/plex/movies/The.Batman.2022.2160p.SDR.WEB-DL.DDP5.1.x264-EVO[TGx]",
  dl_limit: -1,
  dlspeed: 0,
  download_path: "/Volumes/20TeraBoy/plex/downloading",
  downloaded: 16443369503,
  downloaded_session: 16443369503,
  eta: 8640000,
  f_l_piece_prio: false,
  force_start: false,
  infohash_v1: "8c69e01bc2a81a558fe5844c26e570d46e08a0f0",
  infohash_v2: "",
  last_activity: 1660266703,
  magnet_uri:
    "magnet:?xt=urn:btih:8c69e01bc2a81a558fe5844c26e570d46e08a0f0&dn=The.Batman.2022.2160p.SDR.WEB-DL.DDP5.1.x264-EVO%5bTGx%5d",
  max_ratio: -1,
  max_seeding_time: -1,
  name: "The.Batman.2022.2160p.SDR.WEB-DL.DDP5.1.x264-EVO[TGx]",
  num_complete: 390,
  num_incomplete: 2219,
  num_leechs: 7,
  num_seeds: 0,
  priority: 0,
  progress: 1,
  ratio: 3.184979449585747,
  ratio_limit: -2,
  save_path: "/Volumes/20TeraBoy/plex/movies",
  seeding_time: 560640,
  seeding_time_limit: -2,
  seen_complete: 1660265801,
  seq_dl: false,
  size: 16426827616,
  state: "uploading",
  super_seeding: false,
  tags: "",
  time_active: 561604,
  total_size: 16426827616,
  tracker: "",
  trackers_count: 0,
  up_limit: -1,
  uploaded: 52371793949,
  uploaded_session: 52371793949,
  upspeed: 21403,
};
